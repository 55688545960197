import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import cerberus from './assets/images/bg/deadones.jpg';
import ReactPlayer from 'react-player';
import { Howl, Howler } from "howler";
import { bgmusic, soundeffects } from './tools/effects';
import "./App.css";
import "./Extra.css";

// Screen Resolution

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return isMobile ? children : null
}

// Create a Howl instance for the music file
const sound = new Howl({
  src: [bgmusic.Soundtrack0], // Replace with your file path
  loop: true, // Enable looping
  volume: 0.5, // Set volume (optional)
});

const MusicPlayer = () => {
  useEffect(() => {

    // Play the sound
    sound.play();

    // Clean up when the component unmounts
    return () => sound.stop();
  }, []);

  // return (
  //   <div>
  //     <p>Playing music...</p>
  //   </div>
  // );
};

// //Web3Modal constructor
// const web3Modal = new Web3Modal({
//   network: "mainnet", // optional
//   cacheProvider: false, // optional
//   theme: "dark",
//   providerOptions1, // required
// });

function App() {
  const [volumeLevel, setVolumeLevel] = useState(0.5);
  const [audioPlaying, setAudioPlaying] = useState(true);
  const [volIcon, setVolIcon] = useState('vol-mute');
  const network = ['assetchain', 'manta', 'scroll', 'swan', 'neon', 'mantle', 'sonic', 'base', 'core', 'arbitrum', 'bsc', 
    'okx', 'unichain', 'pulsechain','linea', 'soneium','blast','opbnb','polygon'];
  const [soundtrack, setSoundtrack] = useState();
  const [blockchain, setBlockchain] = useState(false);
  const [image, setImage] = useState('');
  const [showImage, setShowImage] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [counter, setCounter] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const submitText = ['BATTLEDOGS', 'ARENA', 'LOGIN', 'TO PLAY', 'FIGHT', 'FOR', 'GLORY', 'EARN', 'MONEY','ONLINE', 'WHILE', 'YOU PLAY',
    'UNIVERSAL', 'BASIC','INCOME', 'FOR ALL','POWERED','BY WEB3','SHOWCASING','GAMEFI', 'AT ITS', 'FINEST'
  ];


  const handlePlus = () => {
    if ((counter + 4) < network.length) {
      setCounter(counter + 1);
    }
  };

  const handleMinus = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  };

  const EffectButton = new Howl({
    src: [soundeffects.Button],
    loop: false, 
  });

  const playButton = () => {
    EffectButton.play();
  }
  const increaseVolume = () => {
    const newVolumeLevel = volumeLevel + 0.1;
    if (volumeLevel === 0) sound.play();
    if (newVolumeLevel <= 1.0) {
      setVolumeLevel(newVolumeLevel);
      Howler.volume(newVolumeLevel);
    }
  };

  const decreaseVolume = () => {
    const newVolumeLevel = volumeLevel - 0.1;
    if (newVolumeLevel >= 0) {
      setVolumeLevel(newVolumeLevel);
      Howler.volume(newVolumeLevel);
    }
  }; 
  
  const cycleVolume = () => {
    if (volumeLevel < 0.1) {
      sound.play();
      setVolumeLevel(0.3);
      Howler.volume(0.3);
    } else if (volumeLevel >= 0.1 && volumeLevel <= 0.3) {
      setVolumeLevel(0.6);
      Howler.volume(0.6);
    } else if (volumeLevel > 0.3 && volumeLevel <= 0.6) {
      setVolumeLevel(1);
      Howler.volume(1);
    } else {
      setVolumeLevel(0);
      sound.stop();
    }
  }; 

  const RenderCenterline = () => {
    return (
    <div className="centerline">
      <div style={counter === 0 ? {opacity: "0.1", cursor: "no-drop"} : { cursor: "pointer"}} align="left">
      <img src={require("./assets/images/icons/up.gif")} onClick={handleMinus} disabled={counter === 0} alt="arrow up" className="navigator" />
      </div>
      {Array.from({ length: 4 }, (_, i) => (
        <a
          key={i}
          href={`https://battledogs.fun/${network[counter + i]}`}
          rel="noreferrer"
        >
          <button className="touchscreen" onClick={playButton}>
            {network[counter + i]} ecosystem
          </button>
        </a>
      ))}
      <div style={(counter + 4) === network.length ? {opacity: "0.1", cursor: "no-drop"} : {cursor: "pointer"}} align="right">
      <img src={require("./assets/images/icons/down.gif")} onClick={handlePlus} disabled={(counter + 4) === network.length} alt="arrow down" className="navigator" />
      </div>
    </div>
    );
  }

  useEffect(() => {
    if (volumeLevel < 0.1) {
      setVolIcon('vol-mute');
    } else if (volumeLevel >= 0.1 && volumeLevel <= 0.3) {
      setVolIcon('vol-low');
    } else if (volumeLevel > 0.3 && volumeLevel <= 0.6) {
      setVolIcon('vol-mid');
    } else {
      setVolIcon('vol-max');
    }
  }, [volumeLevel]);

  useEffect(() => {
      setSoundtrack(bgmusic.Soundtrack0)
  }); 

  const handleImageChange = () => {
    setShowImage(!showImage);
  }
  

  const StartGame = () => {
    return (
      <div className="translation centerstage" align="center" style={{fontFamily: "NexaHeavy"}}>
        <a href={"https://play.battledogs.fun"} rel="noreferrer"><button className="touchscreen" onClick={playButton}> Start Game </button></a>
        <span style={{marginTop: "1vh", color: "gold"}}>Ecosystem Resources</span>
          {RenderCenterline()}
      </div>
    );
  }


  const ImageReveal = () => {
    const [showThirdImage, setShowThirdImage] = useState(false);

    const handleMouseEnter = () => {
      setShowThirdImage(true);
    };

    const handleMouseLeave = () => {
      setShowThirdImage(false);
    };

    return (
      <div className="image-reveal">
        <div className={`image-container2 ${showThirdImage ? 'blurred' : ''}`}>
          <img src={require("./assets/images/bg/battledog-leftsegment.jpg")} alt="Image 1" className="image" />
        </div>
        <div
          className={`image-container2 ${showThirdImage ? 'blurred' : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={require("./assets/images/bg/battledog-rightsegment.jpg")} alt="Image 2" className="image" />
        </div>
        {showThirdImage && (
          <div className="image-container2 third-image-container">
            <img src={require("./assets/images/bg/battledog-midsegment.jpeg")} alt="Image 3" className="image foreground-image" />
          </div>
        )}
      </div>
    );
  };

  const ImageGrid = () => {
    return (
      <div className="image-grid">
        <div className="image-container">
          <img src={require("./assets/images/bg/battledog-leftsegment.jpg")} alt="Image 1" className="image" />
        </div>
        <div className="image-container">
          <img src={require("./assets/images/bg/battledog-midsegment.jpeg")} alt="Image 2" className="image" />
        </div>
        <div className="image-container">
          <img src={require("./assets/images/bg/battledog-rightsegment.jpg")} alt="Image 3" className="image" />
        </div>
      </div>
    );
  };

  const handleMouseEnter = (index) => {
    setActiveImage(index);
  };

  const handleMouseLeave = () => {
    setActiveImage(null);
  };

  // Set up interval to change the text every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % submitText.length);
    }, 2000); // 5000ms = 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);


  return (
    <div className="body"> 
      <div className="animated-image-grid">
        {[1, 2, 3].map((_, index) => (
          <div
            key={index}
            className={`image-container3 ${activeImage === index ? 'active' : ''} ${activeImage !== null && activeImage !== index ? 'fade' : ''}`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={handleImageChange}
          >
            <img
              src={require(`./assets/images/bg/battledogs-${index + 1}.jpg`)}
              alt={`Image3 ${index + 1}`}
              className={`image3 ${activeImage === index ? 'active-image' : ''}`}
            />
            {activeImage !== index && (
              <div className="animated-text-overlay" style={{fontFamily: 'NexaHeavy'}}>
                <span className="animated-char">{submitText[currentTextIndex]}</span>
              </div>
            )}
          </div>
        ))}
        {showImage && (
          <StartGame />
        )}
      </div>
      <MusicPlayer />
        <Mobile>        
        <div className="swmobile">
          <img src={require("./assets/images/icons/vol-.png")} className='soundicon sheightmb' onClick={decreaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
          <img src={require(`./assets/images/icons/${volIcon}.png`)} className='soundicon theightmb' onClick={cycleVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
          <img src={require("./assets/images/icons/vol+.png")} className='soundicon sheightmb' onClick={increaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
        </div>
        </Mobile>       
        <Desktop>
        <div className="swdesktop">
          <img src={require("./assets/images/icons/vol+.png")} className='soundicon sheight' onClick={increaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
          <img src={require(`./assets/images/icons/${volIcon}.png`)} className='soundicon theight' onClick={cycleVolume} style={{backgroundColor: `${blockchain.theme}`}}/>
          <img src={require("./assets/images/icons/vol-.png")} className='soundicon sheight' onClick={decreaseVolume} style={{backgroundColor: `${blockchain.theme}`}}/>     
        </div>
        </Desktop>
  </div>
  );
}

export default App;
